.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  ::ng-deep {
  /*menu loaders*/
    .lds-spinner {
      color: official;
      display: block;
      position: absolute;
      width: 40px;
      height: 40px;
      right: 15px;
      top: 15px;
    } .lds-spinner.center {
      clear: both;
      display: block;
      position: relative;
      top: 0;
      right: 0;
      margin: 0 auto;
    } .lds-spinner div {
          transform-origin: 15px 15px;
          animation: lds-spinner 1.2s linear infinite;
      } .lds-spinner div:after {
              content: " ";
              display: block;
              position: absolute;
              top: 0;
              left: 10px;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background: #c0c0c0;
          } .lds-spinner div:nth-child(1) {
              transform: rotate(0deg);
              animation-delay: -1.1s;
          } .lds-spinner div:nth-child(2) {
              transform: rotate(30deg);
              animation-delay: -1s;
          } .lds-spinner div:nth-child(3) {
              transform: rotate(60deg);
              animation-delay: -0.9s;
          } .lds-spinner div:nth-child(4) {
              transform: rotate(90deg);
              animation-delay: -0.8s;
          } .lds-spinner div:nth-child(5) {
              transform: rotate(120deg);
              animation-delay: -0.7s;
          } .lds-spinner div:nth-child(6) {
              transform: rotate(150deg);
              animation-delay: -0.6s;
          } .lds-spinner div:nth-child(7) {
              transform: rotate(180deg);
              animation-delay: -0.5s;
          } .lds-spinner div:nth-child(8) {
              transform: rotate(210deg);
              animation-delay: -0.4s;
          } .lds-spinner div:nth-child(9) {
              transform: rotate(240deg);
              animation-delay: -0.3s;
          } .lds-spinner div:nth-child(10) {
              transform: rotate(270deg);
              animation-delay: -0.2s;
          } .lds-spinner div:nth-child(11) {
              transform: rotate(300deg);
              animation-delay: -0.1s;
          } .lds-spinner div:nth-child(12) {
              transform: rotate(330deg);
              animation-delay: 0s;
          } @keyframes lds-spinner {
      0% {
          opacity: 1;
      }
    
      100% {
          opacity: 0;
      }
    }




    .loader-1 {
      margin: 10px auto 10px;
      width: 100px;
      height: auto;
      text-align: center;
  
      .title {
          color: #808080;
          text-transform: uppercase;
          text-align: center;
          font-size: 10px;
          letter-spacing: 2px;
      }

      .line {
              width: 1px;
              height: 12px;
              background: #505050;
              margin: 0 1px;
              display: inline-block;
              animation: loaderopacity-1 1000ms infinite ease-in-out;
      }
    

      .line-1, .loader-2 .line-1 {
          animation-delay: 800ms;
      }

      .line-2, .loader-2 .line-2 {
          animation-delay: 600ms;
      }

      .line-3, .loader-2 .line-3 {
          animation-delay: 400ms;
      }

      .line-4, .loader-2 .line-4 {
          animation-delay: 200ms;
      }

      .line-6, .loader-2 .line-6 {
          animation-delay: 200ms;
      }

      .line-7, .loader-2 .line-7 {
          animation-delay: 400ms;
      }

      .line-8, .loader-2 .line-8 {
          animation-delay: 600ms;
      }

      .line-9, .loader-2 .line-9 {
          animation-delay: 800ms;
      }

    }
    .loader-2 {
        .line-1 {
          animation-delay: 800ms;
      }

      .line-2 {
          animation-delay: 600ms;
      }

      .line-3 {
          animation-delay: 400ms;
      }

      .line-4 {
          animation-delay: 200ms;
      }

      .line-6 {
          animation-delay: 200ms;
      }

      .line-7 {
          animation-delay: 400ms;
      }

      .line-8 {
          animation-delay: 600ms;
      }

      .line-9 {
          animation-delay: 800ms;
      }

    }

    @keyframes loaderopacity-1 {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }


    .loader3{
      text-align:center;
      #sparkles > path {
        animation: loader3 1000ms infinite;
        position: absolute;
      } 
      #message {
        text-align: center;
      }
      @for $i from 1 through 29 {
          #sparkles > path:nth-child(#{$i}) {
              animation-delay: (35ms * $i);
          }
      }
      @keyframes loader3 {
          0% {
              opacity: 0;
          }
    
          100% {
              opacity: 1;
          }
      }
  
    }
  

  .loader4 {
      
      position: relative;
      #message {
        color: #b0b0b0;
        text-align: center;
        font-size:.8e;
      }
      .domino {
        text-align: center;
       > div {
          
          display: -webkit-inline-box;
          display: inline-flex;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          -webkit-animation: loader4domino-effect 2.4s infinite ease-in-out;
          animation: loader4domino-effect 2.4s infinite ease-in-out;
          &:nth-child(1) {
            background: #606060;
            -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
            &:after {
              background: #606060;
              -webkit-animation-delay: 0.6s;
              animation-delay: 0.6s;
            }
          }
          &:nth-child(2) {
            background: #808080;
            -webkit-animation-delay: 1.2s;
            animation-delay: 1.2s;
           &:after {
              background: #808080;
              -webkit-animation-delay: 1.2s;
              animation-delay: 1.2s;
            }
          }
          &:nth-child(3) {
            background: #b0b0b0;
            -webkit-animation-delay: 1.8s;
            animation-delay: 1.8s;
         &:after {
              background: #b0b0b0;
              -webkit-animation-delay: 1.8s;
              animation-delay: 1.8s;
            }
          }
          &:nth-child(4) {
            background: #d0d0d0;
            -webkit-animation-delay: 2.4s;
            animation-delay: 2.4s;
           &:after {
              background: #d0d0d0;
              -webkit-animation-delay:2.4s;
              animation-delay: 2.4s;
            }
          }
          &:nth-child(5) {
            background: #f0f0f0;
            -webkit-animation-delay: 3s;
            animation-delay: 3s;
            &:after {
              background: #f0f0f0;
              -webkit-animation-delay: 3s;
              animation-delay: 3s;
            }
          }
          &:after {
              content: "";
              position: relative;
              top: -5%;
              left: -125%;
              opacity: 0;
              -webkit-animation: loader4 2.4s infinite ease-in-out;
              animation: loader4 2.4s infinite ease-in-out;
          }
        }
      }

      @-webkit-keyframes loader4domino-effect {
        20%,100% {
            -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
        }
    }
  
    @keyframes loader4domino-effect {
        20%,100% {
            -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
        }
    }
  
    @-webkit-keyframes loader4 {
        0%, 25% {
            opacity: 1;
        }
  
        26.10%, 33.33%, 100% {
            opacity: 0;
        }
    }
  
    @keyframes loader4 {
        0%, 25% {
            opacity: 1;
        }
  
        26.10%, 33.33%, 100% {
            opacity: 0;
        }
    }


  }


  .loader5 {
    position: relative;
    text-align: center;
    svg {
      border-radius: 3px;
      box-shadow: 2px 2px 5px #fff;
      background: #fff;
      fill: none;
      stroke: #c0c0c0;
      stroke-linecap: round;
      stroke-width: 8%;
    }
    use {
        stroke: #808080;
        animation: loader5a 2s linear infinite;
    }
    #message {
      color: #c0c0c0;
      font-size: 1em;
      margin-top: -.5em;
    }
    @keyframes loader5a {
      to {
          stroke-dashoffset: 0px;
      }
  }
  
}

.loader6 {
  
  position: relative;
  text-align: center;
  #message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: auto;
    animation: loader6load 1.2s infinite 0s ease-in-out;
    animation-direction: alternate;
    text-shadow: 0 0 1px white;
    
    color:#808080;
  }
  @keyframes loader6load {
    0% {
        opacity: 0.08;
        filter: blur(5px);
        letter-spacing: 3px;
    }
  
    100% {
    }
  }
}



.loader7 {
  color: #c0c0c0;
  text-indent: -3000em;
  overflow: hidden;
  border-radius: 50%;
  margin: 10px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load7 1.7s infinite ease, round 1.7s infinite ease;
  animation: load7 1.7s infinite ease, round 1.7s infinite ease;


  @-webkit-keyframes load7 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  
    5%, 95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  
    10%, 59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
  
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
  
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
  
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  
  @keyframes load7 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  
    5%, 95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  
    10%, 59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
  
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
  
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
  
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  
  @-webkit-keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
  
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
  
  @keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
  
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
  
  

}

 

.loader10 {
  position: relative;
  margin:0 auto;
  .loader__bar {
      position: absolute;
      bottom: 0;
      width: 12%;
      height: 90%;
      background: #c0c0c0;
      -webkit-transform-origin: center bottom;
      transform-origin: center bottom;
      box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
      &:nth-child(1) {
          left: 0px;
          -webkit-transform: scale(1, 0.2);
          transform: scale(1, 0.2);
          -webkit-animation: loader10barUp1 4s infinite;
          animation: loader10barUp1 4s infinite;
      }
      &:nth-child(2) {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
        -webkit-animation: loader10barUp2 4s infinite;
        animation: loader10barUp2 4s infinite;
      }
      &:nth-child(3) {
        -webkit-transform: scale(1, 0.6);
        transform: scale(1, 0.6);
        -webkit-animation: loader10barUp3 4s infinite;
        animation: loader10barUp3 4s infinite;
      }
      &:nth-child(4) {
          -webkit-transform: scale(1, 0.8);
          transform: scale(1, 0.8);
          -webkit-animation: loader10barUp4 4s infinite;
          animation: loader10barUp4 4s infinite;
      }
      &:nth-child(5) {
          -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
          -webkit-animation: loader10barUp5 4s infinite;
          animation: loader10barUp5 4s infinite;
      }
  }         
  .loader__ball {
    position: absolute;
    left: 0;
    background: #c0c0c0;
    border-radius: 50%;
    -webkit-animation: load10 4s infinite;
    animation: load10 4s infinite;
  }   




 
  @-webkit-keyframes load10 {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
  
    5% {
        -webkit-transform: translate(8px, -14px);
        transform: translate(8px, -14px);
    }
  
    10% {
        -webkit-transform: translate(15px, -10px);
        transform: translate(15px, -10px);
    }
  
    17% {
        -webkit-transform: translate(23px, -24px);
        transform: translate(23px, -24px);
    }
  
    20% {
        -webkit-transform: translate(30px, -20px);
        transform: translate(30px, -20px);
    }
  
    27% {
        -webkit-transform: translate(38px, -34px);
        transform: translate(38px, -34px);
    }
  
    30% {
        -webkit-transform: translate(45px, -30px);
        transform: translate(45px, -30px);
    }
  
    37% {
        -webkit-transform: translate(53px, -44px);
        transform: translate(53px, -44px);
    }
  
    40% {
        -webkit-transform: translate(60px, -40px);
        transform: translate(60px, -40px);
    }
  
    50% {
        -webkit-transform: translate(60px, 0);
        transform: translate(60px, 0);
    }
  
    57% {
        -webkit-transform: translate(53px, -14px);
        transform: translate(53px, -14px);
    }
  
    60% {
        -webkit-transform: translate(45px, -10px);
        transform: translate(45px, -10px);
    }
  
    67% {
        -webkit-transform: translate(37px, -24px);
        transform: translate(37px, -24px);
    }
  
    70% {
        -webkit-transform: translate(30px, -20px);
        transform: translate(30px, -20px);
    }
  
    77% {
        -webkit-transform: translate(22px, -34px);
        transform: translate(22px, -34px);
    }
  
    80% {
        -webkit-transform: translate(15px, -30px);
        transform: translate(15px, -30px);
    }
  
    87% {
        -webkit-transform: translate(7px, -44px);
        transform: translate(7px, -44px);
    }
  
    90% {
        -webkit-transform: translate(0, -40px);
        transform: translate(0, -40px);
    }
  
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
  }   
  
  @keyframes load10 {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
  
    5% {
        -webkit-transform: translate(8px, -14px);
        transform: translate(8px, -14px);
    }
  
    10% {
        -webkit-transform: translate(15px, -10px);
        transform: translate(15px, -10px);
    }
  
    17% {
        -webkit-transform: translate(23px, -24px);
        transform: translate(23px, -24px);
    }
  
    20% {
        -webkit-transform: translate(30px, -20px);
        transform: translate(30px, -20px);
    }
  
    27% {
        -webkit-transform: translate(38px, -34px);
        transform: translate(38px, -34px);
    }
  
    30% {
        -webkit-transform: translate(45px, -30px);
        transform: translate(45px, -30px);
    }
  
    37% {
        -webkit-transform: translate(53px, -44px);
        transform: translate(53px, -44px);
    }
  
    40% {
        -webkit-transform: translate(60px, -40px);
        transform: translate(60px, -40px);
    }
  
    50% {
        -webkit-transform: translate(60px, 0);
        transform: translate(60px, 0);
    }
  
    57% {
        -webkit-transform: translate(53px, -14px);
        transform: translate(53px, -14px);
    }
  
    60% {
        -webkit-transform: translate(45px, -10px);
        transform: translate(45px, -10px);
    }
  
    67% {
        -webkit-transform: translate(37px, -24px);
        transform: translate(37px, -24px);
    }
  
    70% {
        -webkit-transform: translate(30px, -20px);
        transform: translate(30px, -20px);
    }
  
    77% {
        -webkit-transform: translate(22px, -34px);
        transform: translate(22px, -34px);
    }
  
    80% {
        -webkit-transform: translate(15px, -30px);
        transform: translate(15px, -30px);
    }
  
    87% {
        -webkit-transform: translate(7px, -44px);
        transform: translate(7px, -44px);
    }
  
    90% {
        -webkit-transform: translate(0, -40px);
        transform: translate(0, -40px);
    }
  
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
  }
  
  @-webkit-keyframes loader10barUp1 {
    0% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
  
    40% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
  
    50% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
  
    90% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
  
    100% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
  }
  
  @keyframes loader10barUp1 {
    0% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
  
    40% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
  
    50% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
  
    90% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
  
    100% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
  }
  
  @-webkit-keyframes loader10barUp2 {
    0% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
  
    40% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
  
    50% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
  
    90% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
  
    100% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
  }
  
  @keyframes loader10barUp2 {
    0% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
  
    40% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
  
    50% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
  
    90% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
  
    100% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
  }
  
  @-webkit-keyframes loader10barUp3 {
    0% {
        -webkit-transform: scale(1, 0.6);
        transform: scale(1, 0.6);
    }
  
    100% {
        -webkit-transform: scale(1, 0.6);
        transform: scale(1, 0.6);
    }
  }
  
  @keyframes loader10barUp3 {
    0% {
        -webkit-transform: scale(1, 0.6);
        transform: scale(1, 0.6);
    }
  
    100% {
        -webkit-transform: scale(1, 0.6);
        transform: scale(1, 0.6);
    }
  }
  
  @-webkit-keyframes loader10barUp4 {
    0% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
  
    40% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
  
    50% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
  
    90% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
  
    100% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
  }
  
  @keyframes loader10barUp4 {
    0% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
  
    40% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
  
    50% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
  
    90% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
  
    100% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
  }
  
  @-webkit-keyframes loader10barUp5 {
    0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
  
    40% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
  
    50% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
  
    90% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
  
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
  }
  
  @keyframes loader10barUp5 {
    0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
  
    40% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
  
    50% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
  
    90% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
  
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
  }
  


}

.mat-mdc-progress-bar {
 
  width: 80%;
}

.loader11 {
  position: relative;
  margin: 0 auto;
  .loader--dot {
    animation-name: load11;
    animation-timing-function: ease-in-out;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    border-radius: 100%;
    background-color: black;
    position: absolute;
    border: 2px solid white;  
    &:first-child {
      background-color: #e0e0e0;
      animation-delay: 0.5s;
    }
    &:nth-child(2) {
      background-color: #c0c0c0;
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      background-color: #a0a0a0;
      animation-delay: 0.3s;
    }
    &:nth-child(4) {
      background-color: #808080;
      animation-delay: 0.2s;
    }
    &:nth-child(5) {
      background-color: #606060;
      animation-delay: 0.1s;
    }
    &:nth-child(6) {
      background-color: #404040;
      animation-delay: 0s;
    }
  }
  .loader--text {
    position: absolute;
    color:#c0c0c0;
    left: 0;
    right: 0;
    width: 100%;
    padding-left: 31%;
    margin: auto;
    &:after {
        font-weight: normal;
        animation-name: load11-text;
        animation-duration: 3s;
        animation-iteration-count: infinite;
    }    
  }
  
}

.loader12 {
  position: relative;
  margin: 0 auto;
  .loader--text {
    position: relative;
    top: 0;
    color: #c0c0c0;
    left: 0;
    right: 0;
    width: 100%;
    padding-left: 0;
    text-align:center;
    margin: auto;
    font-size: 1.5em;
    &:after {
      content: "";
      font-weight: normal;
      animation-name: load12-text;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      position: absolute;
    }
  }
  @keyframes load12-text {
    0% {
        content: "";
    }
  
    25% {
        content: ".";
    }
  
    50% {
        content: "..";
    }
  
    75% {
        content: "...";
    }
  }
  
  @keyframes load12-subtext {
    0% {
        color: transparent;
    }
  
    50% {
        color: #808080;
    }
  
    100% {
        color: transparent;
    }
  }
}






/*menu loaders*/
}




