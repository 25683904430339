.ic {
    background-image: url(/assets/images/icons-new.svg) !important;
    width: 64px;
    height: 64px;
    display: block;
    overflow: hidden;
    background-position-y: 0;
    background-repeat: no-repeat;
    background-size: 8428px 64px;
}

.ic.ic-c-rep {
    background-position-x: 0;
}

.ic.ic-v-rep {
    background-position-x: -64px;
}

.ic.ic-s-rep {
    background-position-x: -128px;
}

.ic.ic-o-rep {
    background-position-x: -6464px;
}