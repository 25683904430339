@import 'decorations';
@import 'colors';

.mat-divider {
  margin: 2rem 0 !important;
}

.mat-mdc-card {
  @extend .box-shadow-b;
  margin-bottom: 1.5rem;
  padding: 1.6rem 1.2rem;
}

.mat-mdc-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface {
  padding: 2rem;
}

mat-label {
  font-size: 1.3rem;
}

::ng-deep input {
  font-size: 1.3rem;

  &:-webkit-autofill::first-line {
    font-size: 1.3rem;
  }
}

::ng-deep .mdc-notched-outline__leading,
::ng-deep .mdc-notched-outline__notch,
::ng-deep .mdc-notched-outline__trailing {
  border-color: $grey-300;

  &:hover {
    border-color: $grey-400;
  }
}

.mat-mdc-icon-button .mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-icon-button .mat-mdc-form-field-icon-suffix > .mat-icon {
  font-size: 2rem;
}

.mat-mdc-icon-button svg,
.mat-mdc-icon-button img {
  width: 1.8rem !important;
  height: 1.8rem !important;
}

.mat-mdc-form-field:not(.ng-invalid.ng-touched) {
  .mdc-notched-outline {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: $grey-300;
    }

    &:hover {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: $grey-400;
      }
    }
  }
}
.mat-mdc-form-field {
  .mat-mdc-form-field-infix {
    padding: 1.4rem 0;
  }

  .mat-mdc-form-field-input-control {
    font-size: 1.3rem;
  }

  .mat-mdc-text-field-wrapper {
    max-height: 5rem;
  }

  .mat-mdc-form-field-subscript-wrapper {
    min-height: 2.5rem;

    .mat-mdc-form-field-error {
      font-size: 1.1rem;
    }
  }

  .mat-mdc-form-field-input-control.cdk-text-field-autofilled,
  .mat-mdc-form-field-input-control.cdk-text-field-autofill-monitored {
    font-size: 1.3rem;
    letter-spacing: 0.025rem;
  }

  .mdc-text-field--outlined {
    .mat-mdc-form-field-flex {
      align-items: center;

      .mdc-floating-label {
        font-size: 1.3rem;
        padding-bottom: 3px;
      }
    }
  }
}

.mat-mdc-form-field-type-mat-date-range-input {
  .mdc-text-field--outlined {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        padding-top: 2rem;
      }
    }
  }
}

.mat-mdc-form-field-type-mat-select {
  .mat-mdc-form-field-flex {
    max-height: 5rem;
  }

  .mat-mdc-text-field-wrapper {
    max-height: 5rem;

    .mat-mdc-form-field-flex {
      .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
        top: 26px;
      }
    }
  }
}

.mat-mdc-card > .mat-divider-horizontal {
  position: relative !important;
  width: inherit !important;
}

.mat-mdc-card-header {
  padding-top: 2rem;
}

/* Mat Select - Form Field */

/* Mat Select */

.mat-mdc-select {
  font-size: 1.4rem;
  letter-spacing: 0.025rem;

  .mat-mdc-select-value-text {
    padding-top: 1.2rem;
    font-size: 1.3rem;
  }
}

.mat-mdc-option.mdc-list-item {
  min-width: 10rem;
}

.cdk-overlay-container .mat-mdc-option .mdc-list-item__primary-text {
  font-size: 1.3rem;
  letter-spacing: 0.025rem;
  padding: 1.3rem 0;
}

/* Select options panel height */
::ng-deep .cdk-overlay-pane .mdc-menu-surface.mat-mdc-select-panel {
  max-height: 30rem !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-appearance-standard
  .mat-button-toggle-button
  .mat-button-toggle-label-content {
  line-height: 3rem;
}

::ng-deep .mat-mdc-button .mat-icon,
::ng-deep .mat-mdc-button.mat-mdc-unelevated-button .mat-icon {
  font-size: 2rem;
  height: 2rem;
  width: 2rem;
}

.mdc-button.mat-mdc-outlined-button,
.mat-mdc-button.mat-mdc-outlined-button,
.mat-mdc-button.mat-mdc-unelevated-button,
.mat-mdc-button.mat-mdc-button-base {
  padding: 1rem 2rem;
}

.mat-mdc-slide-toggle label {
  padding-top: 3px;
}

.mat-mdc-menu-panel {
  background-color: $grey-100;
}

// Note - rule doesn't connect
::ng-deep .mat-mdc-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
  transform: scale(0.8);
}

.mat-mdc-list-base .mat-mdc-list-item {
  .mdc-list-item__primary-text {
    font-size: 1.3rem;
    letter-spacing: 0.025rem;
  }

  .fa-regular,
  .fa-solid {
    font-size: 1.5rem;
  }
}

.mat-tree {
  .mat-tree-node {
    min-height: 4.6rem;
  }
}

/* Table Rows */
.mat-mdc-table .mat-mdc-row {
  background-color: #fcfcfc;

  &:nth-child(odd) {
    background-color: #f3f3f3;
  }

  &:hover {
    background-color: #fafafa !important;
  }
}

/* Table Header */
.mat-mdc-table mat-header-row.mat-mdc-header-row {
  background-color: $grey-200;
  font-size: 1.3rem;
}

/* Table Cells */
.mat-mdc-table .mat-mdc-cell,
.mat-table .mat-mdc-header-cell {
  font-size: 1.3rem;
  border-bottom: 0;

  .mat-mdc-button-base {
    line-height: 2.6rem;
    letter-spacing: 0.025rem;
  }
}

.mat-mdc-table .mat-mdc-cell:not(:first-of-type) {
  padding: 0 1rem;
}

.table-row-padded {
  padding-left: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
}

.mat-mdc-slide-toggle {
  margin: 1rem 0;
}
/*fixing double vertical scroll bar*/
::ng-deep {
  .hasMenu {
    .main {
      height: auto !important;
      //max-width: 100vw!important;
    }
  }
  .mat-drawer-content {
    overflow: unset !important;
  }
}

.mat-mdc-slide-toggle {
  .mat-ripple-element {
    background-color: transparent !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .mat-slide-toggle-bar {
    height: 20px;
    border-radius: 10px;
    border: 1px solid;
    background-color: $switch-inactive-bg;
    border-color: $switch-inactive-border;
    /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-slide-toggle-persistent-ripple {
      opacity: 0;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-slide-toggle-thumb-container {
      width: 18px;
      height: 18px;
      top: 0;
      /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
      .mat-slide-toggle-thumb {
        border: 1px solid;
        width: 18px;
        height: 18px;
        background-color: $switch-actvie-toggle-bg;
        box-shadow: 0 0 0 0.1rem $switch-actvie-toggle-shadow;
        border-color: $switch-actvie-toggle-border;
      }
    }
  }
  &.mat-checked {
    &.mat-disabled {
      /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
      .mat-slide-toggle-bar {
        background-color: $switch-disabled-bg;
        border-color: $switch-disabled-border;
        /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
        .mat-slide-toggle-thumb-container {
          /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
          .mat-slide-toggle-thumb {
            background-color: $switch-disabled-toggle-bg;
            box-shadow: 0 0 0 0.1rem $switch-disabled-toggle-shadow;
            border-color: $switch-disabled-toggle-border;
          }
        }
      }
    }

    &:not(.mat-disabled) {
      /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
      .mat-slide-toggle-bar {
        background-color: $switch-actvie-bg;
        border-color: $switch-actvie-border;
      }
    }
  }
}

.mat-drawer-inner-container {
  overflow: hidden !important;
}

.mat-mdc-menu-item {
  .mdc-list-item__primary-text {
    font-size: 1.3rem !important;
  }
  .mat-icon {
    margin: 0 !important;
    width: 3rem;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-checked {
  background-color: $blue-700;
}

.mdc-button__label {
  letter-spacing: 0.025rem;
}

.mdc-fab.mdc-fab--mini {
  width: 30px;
  height: 30px;

  .mdc-button__label {
    font-size: 1.2rem;
  }
}

.mat-mdc-chip {
  background-color: $blue-800 !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
.mat-chip-remove {
  color: $blue-400;
}
