@import 'colors';

.outerroundedbox {
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    box-shadow: 0 1px 12px -2px rgb(0 0 0 / 15%);
    background-color: #fafafa;
    margin-bottom: 25px;
    padding: 20px;
}

$bar-shadow: 0 6px 10px -5px rgb(0 0 0 / 40%), inset 0 -17px 17px rgb(0 0 0 / 20%);

.well {
    min-height: 20px;
    padding:  0 0 1rem 0;
    margin-bottom: 20px;
    background-color: #fafafa;
    border: 1px solid #f3f3f3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);

    &.pad {
        padding:  2rem 0 1rem 0;
    }
}

.deep-gradient {
  background: #0f2027; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, $blue-200, $blue-300, $blue-400); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, $blue-200, $blue-300, $blue-400); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.border-dashed {
    border: 2px dashed $grey-200;
}

.box-shadow-a {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px !important;
}

.box-shadow-b {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
}

/* headlines with lines */
.title-rule {
    overflow: hidden;
    text-align: left;

    .ng-fa-icon,
    .fa,
    .fa-regular {
        font-size: 1.6rem;
        margin-right: 1rem;
        padding-left: 0.5rem;
        display: inline-block;
        width: 2rem;
    }

    &.icon {
        span {
            padding-left: 1rem;
        }
    }
}
.title-rule > span {
   position: relative;
   display: inline-block;
}
.title-rule > span:before, .title-rule > span:after {
   content: '';
   position: absolute;
   top: 50%;
   border-bottom: 1px solid $grey-200;
   width: 591px; /* half of limiter*/
   margin: 0 20px;
}
.title-rule > span:before {
   right: 100%;
}
.title-rule > span:after {
   left: 100%;
}

.number-circle {
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    padding: 6px;
    display: inline-block;

    background: #fff;
    border: 2px solid #666;
    color: #666;
    text-align: center;

    font-size: 2rem;
    font-weight: 500;
}

.pod {
    border: 1px solid #f0f0f0;
    border-radius: 1rem;
    margin: 1rem;
    padding: 1.5rem 1.5rem 2rem;
}

.prompt {
    margin: 2rem;
    padding: 3rem;
    color: $grey-600;
    font-size: 1.4rem;
    display: block;
    border: 2px dashed $grey-200;
    text-align: center;

    .ng-fa-icon {
        font-size: 1.6rem;
        margin-right: 1rem;
    }
}