$blue-100: #072537;
$blue-200: #031a39;
$blue-300: #081f49;
$blue-400: #123b69;
$blue-500: #1b6491;
$blue-600: #b8dbf1;
$blue-700: #daecf7;
$blue-800: #e8f2f8;
$blue-900: #e3f4ff;

$blue-white: #f0f8fd;

$green-400: #49c59a;
$green-500: #6f9a43;

$grey-50: rgba(251, 251, 251);
$grey-100: rgb(247, 247, 247);
$grey-200: rgba(242, 242, 242);
$grey-300: rgba(228, 228, 228);
$grey-400: rgba(180, 180, 180); // Matches Material Form outline color
$grey-450: rgba(0, 0, 0, 0.46);
$grey-500: rgba(0, 0, 0, 0.6); // Matches Material Form text color
$grey-600: rgba(110, 110, 110, 1);
$grey-700: rgba(85, 85, 85, 1);
$grey-800: rgba(54, 54, 54, 1);
$grey-900: rgba(38, 38, 38, 1);

$white: #fff;
$error: #dd5959;
$warn: #e9a36b;
$yellow-300: #ffe8a5;
$yellow-400: #ffe478;

$soft-red: #ea6a68;
$soft-orange: #eabc69;
$soft-yellow: #e9ea68;
$soft-green: #93ea68;
$soft-teal: #68ead5;
$soft-blue: #68d3ea;

$report-green: #88ff88;
$report-red: #ff7373;
$report-orange: #ffd073;
$report-yellow: #ffff73;

$md-red: (
  50: $soft-red,
  100: $soft-red,
  200: lighten($soft-red, 40),
  300: lighten($soft-red, 30),
  400: lighten($soft-red, 20),
  450: lighten($soft-red, 10),
  500: $soft-red,
  // Matches Material Form text color
  600: darken($soft-red, 10),
  700: darken($soft-red, 20),
  800: darken($soft-red, 30),
  900: darken($soft-red, 40),
  contrast: (
    50: $grey-700,
    100: $grey-800,
    200: $grey-900,
    300: $grey-900,
    400: $grey-900,
    450: $grey-900,
    500: $white,
    600: $white,
    700: $white,
    800: $grey-100,
    900: $grey-300,
  ),
);

$md-grey: (
  50: $grey-50,
  100: $grey-100,
  200: $grey-200,
  300: $grey-300,
  400: $grey-400,
  // Matches Material Form outline color
  450: $grey-450,
  500: $grey-500,
  // Matches Material Form text color
  600: $grey-600,
  700: $grey-700,
  800: $grey-800,
  900: $grey-900,
  contrast: (
    50: $grey-800,
    100: $grey-800,
    200: $grey-900,
    300: $grey-900,
    400: $grey-900,
    450: $grey-50,
    500: $grey-50,
    600: $grey-50,
    700: $grey-50,
    800: $grey-100,
    900: $grey-100,
  ),
);

$md-aqua: (
  50: #e9f5fc,
  100: #c5e1eb,
  200: #7dadd2,
  300: #5690b6,
  400: #41719d,
  500: #2a537e,
  600: #255476,
  700: #1f4e6b,
  800: #193b61,
  900: #0f354e,
  A100: #f18cae,
  A200: #d26489,
  A400: #c7426f,
  A700: #b02755,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

/*switch colors*/

$switch-actvie-bg: #64bd63;
$switch-actvie-border: #64bd63;
$switch-actvie-toggle-bg: #ffffff;
$switch-actvie-toggle-border: #ffffff;
$switch-actvie-toggle-shadow: #cbcbcb;

$switch-inactive-bg: #f0f0f0;
$switch-inactive-border: #cbcbcb;
$switch-inactive-toggle-bg: #ffffff;
$switch-inactive-toggle-border: #cbcbcb;
$switch-inactive-toggle-shadow: #cbcbcb;

$switch-disabled-bg: #ffffff;
$switch-disabled-border: #cbcbcb;
$switch-disabled-toggle-bg: #ffffff;
$switch-disabled-toggle-border: #cbcbcb;
$switch-disabled-toggle-shadow: #cbcbcb;
