/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '@angular/material/theming';

// Ngx_toastr
@import 'ngx-toastr/toastr';

// App
@import './styles/animations.scss';
@import './styles/icons.scss';
@import './styles/colors.scss';
@import './styles/decorations.scss';

@include mat.core();

$my-app-primary: mat.define-palette($md-aqua, 500, 700, A200);
$my-app-accent: mat.define-palette($mat-green, 500, 900, A100);
$my-app-warn: mat.define-palette($md-red);
$my-app-theme: mat.define-light-theme((
  color: (
    primary: $my-app-primary,
    accent: $my-app-accent,
    warn: $my-app-warn
  ),
  typography: mat.define-typography-config(
    $font-family: Roboto,
    $headline-1: mat.define-typography-level($font-size: 2.2rem),
    $headline-2: mat.define-typography-level($font-size: 1.8rem),
    $headline-3: mat.define-typography-level($font-size: 1.6rem),
    $body-1: mat.define-typography-level($font-size: 1.3rem),
    $button: mat.define-typography-level(
        $font-size: 1.3rem,
        $font-weight: 600
    ),
  ),
  density: 0
));

$my-app-grey-palette: mat.define-palette($md-grey, 500, 200, 800, 600);
  
//@include angular-material-theme($my-app-theme);
@include mat.core-theme($my-app-theme);
@include mat.all-component-themes($my-app-theme);
@include mat.all-legacy-component-themes($my-app-theme);


html { font-size: 10px; }
html, body { height: 100%; }
body {
    margin: 0;
    font-family: "Libre Franklin", "Helvetica Neue", sans-serif;
    padding: 0;
    background-color: $blue-300;
}

body.mat-typography {
    font-size: 1.3rem;
    letter-spacing: 0.025rem;    

    h1 {
        font-size: 2.6rem;
        font-family: 'Libre Franklin';
        
    }
    h2 {
       font-size: 1.8rem;
       font-family: 'Libre Franklin';
       padding: 0.5rem;
       font-weight: 500;

       &.invert {
            color: #fff;
            background-color: $blue-500;
            padding: 0.5rem;
       }
       
    }
    h3 {
        font-family: 'Libre Franklin';
        font-size: 1.6rem;
        color: mat.get-color-from-palette($my-app-grey-palette, 800);
        font-weight: 500;

        &.invert {
            color: #fff;
            background-color: $blue-500;
            padding: 0.8rem;
            
       }
    }
    h4 {
        font-family: 'Libre Franklin';
        font-size: 1.5rem;
        font-weight: 500;
        color: mat.get-color-from-palette($my-app-grey-palette, 800);
    }
    p {
        font-size: 1.3rem;
        color: mat.get-color-from-palette($my-app-grey-palette, 900);
    }
    button {
        font-size: 1.3rem;
    }

    a,
    a:visited,
    a:hover {
        color: $blue-100;
        padding: 0.5rem;
    }

    label {
        font-size: 1.3rem;
    }
}

.indent {
    padding-left: 2rem;
}

.pad-top {
    margin-top: 2rem;
}

.prompt {
    color: #555555;
}

.actions {
    button {
        margin-left: 1rem;
    }
}

.flex-even {
    flex: 1;
}

.anchor-link {
    display: block;
    padding: 1rem;

    .mat-icon {
        position: relative;
        top: 0.8rem;
    }
}

.pointer { cursor: pointer; }

.nav-list {
    .item {
        margin-right: 1rem;
        margin-bottom: 1rem;
        min-width: 12rem;

        &.new {
            .mat-mdc-outlined-button {
                border-style: dashed;
            }
        }
    }
    button.mat-mdc-button-base {
        width: 100%;
        color: $blue-300;
        padding: 0.5rem 0;
    }
    .ng-fa-icon { 
        margin-top: 1.5rem;
        display: block;
        font-size: 2rem;
        svg {
            path {
                fill:url(#mygradient);
            }
        }
        
    }
}

.small-card {
    max-width: 50rem;
    margin: 0 auto;
    position: relative;
    top: 10vh;
    margin-top: 3rem;
    padding: 5rem;
}

.angular-editor-toolbar .angular-editor-toolbar-set {
    .angular-editor-button {
        padding: 0.8rem !important;
        
    }

    .ae-picker {
        font-size: 1.5rem !important;
        height: 34px;
    }

}

.text {
    color: mat.get-color-from-palette($my-app-grey-palette, 600);
    margin: 0;
}

// The next 4 rules are for the custom input button. Input and label elements
// must be siblings for checked and active styles to be applied.

.button-input {
  opacity: 0;
  position: fixed;
  width: 0;
}

.button-label {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $grey-400;
  border-radius: 6px;
  min-width: 14rem;
  min-height: 8.4rem;
  margin: 0;
  &:hover {
    background-color: $grey-100;
    cursor: pointer;
  }
}

.button-input:checked + .button-label {
  background-color: $grey-100;
  border: .2rem solid mat.get-color-from-palette($my-app-primary, 'default');
}

.button-input:active + .button-label {
  background-color: $grey-300;
  border: .2rem solid mat.get-color-from-palette($my-app-primary, 'default');
}

.fw-bold {
    font-weight: 700;
}

.pac-item {
    font-size: 1.4rem;
    padding: 1rem 0.5rem;
}
.pac-container {
    min-width: 30rem;
}
.pac-item-query {
    font-size: 1.4rem;
}


/** Checkbox Toggle **/
.aps-checkbox {

    .checkbox {
        appearance: none;
    }
  
    .label {
        padding: 0 1.6rem;
        border: 1px solid $grey-400;
        border-radius: 4px;
        height: 3.6rem;
        margin-left: .3rem;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 1.3rem;
        margin-bottom: 2rem;
        
        &:hover {
            background-color: $grey-100;
            cursor: pointer;
        }
    }
  
    input:checked + .label {
        background-color: $grey-100;
        border: .2rem solid mat.get-color-from-palette($my-app-primary, 'default');
    }
}

  
.fakeframe{
    padding: 1rem;
    display: inline-block;
    position: relative;
    flex: auto;
    min-width: 0;
    max-width: 28rem; 
    margin: 1rem 0;
    color:#0000001f;
    border:1px solid currentColor;
    border-radius: 5px;
    height: 50px;
    padding-top: 10px;
  
    .fakelable{
      position: absolute;
      left: 0;
      font: inherit;
      pointer-events: none;
      width: fit-content;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      transform-origin: 0 0;
      transform: translateY(-1.59375em) translateX(0.25em) scale(.75);
      color: #0009;
      background-color: #FFFFFF;
      padding-left: 0.5em;
      padding-right: 0.5em;
      
    }
    *{
      color: #222222;
    }
    .selection {
            color: #000;
            padding-bottom: 10px;
            width: 235px;
            overflow: hidden;
            flex-grow: 0;
            border-bottom: 0 none transparent!important;
        }
  
  
  }

@import './styles/material-overrides.scss';
